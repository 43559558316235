import { Button } from "@components/Button"

import { AltAboutJk } from "@components/AboutJk"
import { SustainableGated } from "@components/Form"
import { Layout } from "@components/Layout"
import { Arrow } from "@components/UI"
import React from "react"
/*global gtag*/
import { SalesForm } from "@components/Form"
import { Seo } from "@components/Seo"
import { usePageSetup } from "@components/usePageSetup"
import Atom from "@svg/sustainable-brands/atom.svg"
import { splitText } from "@utils"
import { graphql, navigate } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { StaticPost } from "../components/Blog/StaticPost"
import { handlePdfDownload } from "../utils"

const SustainableGuidePage = ({ data }) => {
  const { aboutJkImage, formImage, posts } = data
  const { themeColor } = usePageSetup({
    themeColor: "bg-teal-dark",
    navigationColor: "text-white",
    displayGeneralForm: false,
    footnote: `1 - BusinessWire, “Recent Study Reveals More Than a Third of Global Consumers Are Willing to Pay More for
            Sustainability as Demand Grows for Environmentally Friendly Alternatives,” 2021.`,
  })

  const isBrowser = typeof window !== "undefined"

  const contactSubmission = () => {
    if (!isBrowser) {
      return
    }

    gtag("event", "contact_us_form_submission", { event_category: "contact_us", event_label: "sustainability" })
  }

  const handlFormSubmission = () => {
    handlePdfDownload("/documents/jk-sustainability-ebook.pdf", "JK_Sustainability-Storytelling-Guide")

    navigate("/sustainability")
  }

  return (
    <Layout>
      <Seo title="Sustainable Brands" description="" />
      <div className="overflow-hidden">
        <div className={themeColor} style={{ height: "94px" }}></div>
        <div className={themeColor}>
          <div className="container relative">
            <div className="grid grid-cols-1 lg:grid-cols-2">
              <div>
                <h1
                  className="mt-12 text-clamp-40-80"
                  data-aos="stagger"
                  dangerouslySetInnerHTML={{
                    __html: splitText({
                      "text-white": "Sustainability storytelling:",
                      break: "",
                      "text-gray-darkest": "What’s your sweet spot?",
                    }),
                  }}
                />
                <p data-aos="fade-up" className="mt-9 pr-15">
                  60% of consumers place a high priority on sustainability in their buying decisions.<sup>1</sup> But
                  how do you find the right balance between saying too much—and not enough?{" "}
                  <span className="font-bold">Download our guide to find out.</span>
                </p>
              </div>
              <div className="flex justify-center grid-cols-6 pb-12 lg:grid mt-9 lg:mt-0">
                <div
                  data-aos="fade-up"
                  className="col-span-4 lg:col-start-2 lg:col-span-5"
                  style={{ maxWidth: "589px" }}
                >
                  <div>
                    <GatsbyImage
                      objectFit="contain"
                      className="w-full h-full"
                      image={getImage(formImage)}
                      alt="Gated Form Hero"
                    />
                  </div>
                  <div className="py-16 bg-white px-14">
                    <h2 className="text-clamp-20-26">
                      Learn how to tell your best—and most balanced—sustainability story.
                    </h2>
                    <div className="mt-5">
                      <SustainableGated
                        id="5658"
                        subject="Sustainable Brands"
                        recipient="katiek@jkdesign.com, markm@jkdesign.com"
                        fn={() => handlFormSubmission()}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <main>
          <section className="py-36">
            <div className="container flex justify-center">
              <div className="flex w-full grid-cols-8 gap-8 md:w-8/12 sm:grid">
                <div className="col-span-3" data-aos="fade-right">
                  <Atom />
                </div>
                <div className="col-span-5" data-aos="fade-left">
                  <h3 clasName="font-clamp-20-26">Sustainability isn’t an option today—it’s an expectation.</h3>
                  <p className="mt-8">
                    We’re ready to help you create and tell a powerful sustainability, ESG, or CSR story that elevates
                    your brand, brings your data to life—and makes emotional connections with your audiences.
                  </p>
                  <Button className="mt-8 text-teal-dark" link="#sustainability-form">
                    Get in touch with an expert now
                  </Button>
                </div>
              </div>
            </div>
          </section>

          <section className="py-clamp-10-30 bg-gray">
            <div className="container">
              <h3 className="text-white text-clamp-30-45">We’re always thinking about sustainability storytelling.</h3>
              <div className="relative grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3 mt-15">
                {posts?.edges?.[2] ? (
                  <StaticPost post={posts.edges[2].node}>
                    <Button
                      external={true}
                      link={`/ideas-and-insights/${posts.edges[2].node.slug}`}
                      className="text-white"
                    >
                      Learn more
                    </Button>
                  </StaticPost>
                ) : null}
                {posts?.edges?.[1] ? (
                  <StaticPost post={posts.edges[1].node}>
                    <Button
                      external={true}
                      link={`/ideas-and-insights/${posts.edges[1].node.slug}`}
                      className="text-white"
                    >
                      Learn more
                    </Button>
                  </StaticPost>
                ) : null}
                {posts?.edges?.[0] ? (
                  <StaticPost post={posts.edges[0].node}>
                    <Button
                      external={true}
                      link={`/ideas-and-insights/${posts.edges[0].node.slug}`}
                      className="text-white"
                    >
                      Learn more
                    </Button>
                  </StaticPost>
                ) : null}
              </div>
            </div>
          </section>

          <Arrow colorTop="bg-transparent" colorBottom="bg-gray-darkest" fillColor="text-gray-darkest" />
          <section className="pt-clamp-44-62 pb-clamp-37-60">
            <AltAboutJk>
              <AltAboutJk.Content>
                JK is a women-owned brand and communications agency with the unique ability to build distinctive stories
                for all the audiences you need to reach—from customers to employees to investors and beyond.
              </AltAboutJk.Content>
              <AltAboutJk.ImageV2 image={aboutJkImage} shape="one" />
            </AltAboutJk>
          </section>
          <Arrow colorTop="bg-gray-darkest" colorBottom="bg-gray-darkest" fillColor="text-gray-darkest" />
          <div id="sustainability-form" style={{ scrollMarginTop: "78px" }}></div>
          <section className="bg-purple-light pt-clamp-34-51 pb-clamp-27-40">
            <div className="container flex lg:justify-center">
              <div className="xl:w-10/12">
                <div className="grid-cols-10 lg:grid">
                  <div className="col-span-8">
                    <h2
                      data-aos="stagger"
                      className="text-title"
                      dangerouslySetInnerHTML={{
                        __html: splitText({
                          "text-white": "Let’s build a stronger ",
                          break: "",
                          "text-gray-darkest": "sustainability story, together.",
                        }),
                      }}
                    />
                  </div>
                </div>
                <div className="grid-cols-10 lg:grid mt-clamp-7-11">
                  <div className="col-span-8 xl:col-span-7">
                    <p data-aos="fade-up">Please fill out the form below and we’ll be in touch soon.</p>
                  </div>
                </div>
              </div>
            </div>
            <section className="container mt-24">
              <div className="mx-auto xl:w-10/12">
                <SalesForm
                  id="632"
                  subject="JK Design - Sustainability"
                  recipient="katiek@jkdesign.com, markm@jkdesign.com"
                  fieldColor="bg-teal-light"
                  fn={contactSubmission}
                />
              </div>
            </section>
          </section>
        </main>
      </div>
    </Layout>
  )
}

export default SustainableGuidePage

export const SustainableGuidePageQuery = graphql`
  query sustainableGuidePagePageQuery {
    heroImage: file(relativePath: { eq: "sustainability/hero.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    aboutJkImage: file(relativePath: { eq: "sustainable-branding/about.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    formImage: file(relativePath: { eq: "sustainable-branding/form-header.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    posts: allWpPost(filter: { id: { in: ["cG9zdDo0MzAy", "cG9zdDo0NDI5", "cG9zdDo0OTcx"] } }) {
      edges {
        node {
          id
          title
          slug
          excerpt
          date(formatString: "MMMM DD, YYYY")
          categories {
            nodes {
              name
              slug
            }
          }
          article {
            theme
          }
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED)
                }
              }
            }
          }
        }
      }
    }
  }
`
